<template>
    <Loading :isLoading="isLoading" />
    <div class="bg-white m-0 px-5 md:px-3 md:m-5 ">
        <div class="grid grid-cols-1 md:grid-cols-2 px-0 md:px-0 lg:px-20 mt-10">

            <div class="w-full md:w-4/5 md:mx-auto mt-5">
                <p class="text-3xl font-bold roboto-regular">{{ questionTitle }}</p>
                <Suspense>
                    <template #default>
                        <ComponentsPersonalData 
                            :key="reRender"
                            v-if="listOfQuestionPeronalInfo.length>0"
                            class="h-auto"
                            :listQuestionPersonal="listOfQuestionPeronalInfo" ref="ComponentsPersonalDataRef" 
                            v-on:return-personal-data="changeModelPersonalData($event)" :classContainer="'flex flex-col w-full mt-5'" :rulesValidationPersonal="validations" :modelPersonal="vidaSimpleModel"                            
                            :ClassInputsQuestion="'input-question w-full p-2 outline-transparent ring-transparent focus:outline-none border-2 focus:ring focus:border-cherryColor active:border-cherryColor rounded-sm w-full mt-2'"
                            :ClassContainerQuestion="'question-container w-full font-light'"
                            :ShowPlasceholderQuestion="false"
                            :ClassErrorsContainerQuestion="'mt-2 flex flex-col'"
                            :ClassErrorsTextQuestion="'text-red-700 text-sm '"
                        />
                    </template>
                    <template #fallback>
                        <p>Cargando...</p>
                    </template>
                </Suspense>
                
                <div class="w-full flex justify-center">
                   <input type="button" :disabled="!isActive" :class="{'button-disabled cursor-default': !isActive, 'bg-mustardDark cursor-pointer': isActive}" class="w-full text-white px-5 py-2 mx-auto my-4 h-14 md:h-14  rounded text-center font-medium text-xl" @click="next()" value="Continuar">        
                </div>
            </div>
            <div class="w-full bg-white hidden md:block md:py-14">
                <img :src="imageCategory" :alt="questionTitle" class="mx-auto" >
            </div>
        </div> 
    </div>
</template>

<script>
import { ref, toRef, onMounted, watch } from 'vue';
import { helpers, requiredIf, minLength, maxLength } from '@vuelidate/validators';
import Products from '@/classes/Products.js';
import Quote from '@/classes/Quote.js';

import ValidationsStructure from '@/vuelidate_rules/VidaSimple/VidaSimple.json';

import Swal from 'sweetalert2';
import Loading from '@/components/Loading/VueLoading.vue';
import ComponentsPersonalData from 'components-personal-data';
import { useRouter } from 'vue-router';
import moment from "moment";
import { get } from 'axios';
import { validityRFC, validateMatchRFCwithName } from '@/api/user';
import User from "@/classes/User"

export default {
    props:{
        category: {
            type: String,
            default: () => "personal-data"
        },
        lastPage: {
            type: Number,
            default: 0
        }
    },
    watch:{
        listOfQuestionPeronalInfo:{
            async handler(value){
                for(const question of value){
                    if(question.question_type=="select" && question.endpoints_catalog.length>0 && question.showQuestion && question.options.length==0){
                        await this.searchCatalogInQuestion(question);
                    }
                }
            },
            deep:true
        }
            
    },
    setup(props){
        const router = useRouter();
        const tokenAuthorization = ref(null);
        const isLoading = ref(true);
        const reRender = ref(null);
        const currentCategory = ref('');
        const ComponentsPersonalDataRef = ref(null);
        
        
        const questionsList = ref([]);
        const allQuestionsList = ref(null);
        const indexCategory =  ref(0);

        const userInfo = ref(null);
        const validations = ref({});
        const productsClass = ref(null);
        const quoteClass = ref(null);
        const goBack = toRef(props, 'lastPage')

        let questionTitle = ref('');
        let vidaSimpleValidations = ref({});
        let vidaSimpleModel = ref({});
        let isActive = ref(false);
        let imageCategory = ref('');
        let data = ref([]);
        let quoteData = ref([]);
        let changeInFlow = ref(false);
        let isValidRfc = ref(true);

        let userClass = new User();
        
        onMounted(async()=>{
            if(props.category != 'personal-data'){
                productsClass.value = new Products();
                quoteClass.value = new Quote();
                await getQuestions();
                tokenAuthorization.value = await getTokenAuthorization();
                validations.value = vidaSimpleValidations;
                    
                reRender.value = true;

            }
            isLoading.value = false;
        });

        watch(goBack, (val)=> {
            if(val>0){
                back();
            }else if(indexCategory.value){
                console.log("Aun no hay pagina hacia atras", val);
            }
        });

        watch( userInfo, (value) => {
            if (value.contractor_other_than_the_applicant == '') {
                isActive.value = false;
                isValidRfc.value = true;
            }
        });

        watch(userInfo, async (value) => {
            if ( currentCategory.value == 'contracting_data') {
                if ( !isEmptyObject(value) && value.contractor_other_than_the_applicant == 'si') {

                    let rfc = value.input_rfc; 
                    let birthdate = value.birthdate_contracting_data;
                    let name = value.input_nombre_contracting_data;
                    let middleName = '';
                    let paternalName = value.input_paternal_name_contracting_data;
                    let maternalName = value.input_maternal_name_contracting_data;
                    
                    if ( name.length >= 2 && paternalName.length > 2 ) {
                        isValidRfc.value = await validityRFC(rfc, birthdate, name, '', paternalName, maternalName );
                        isActive.value = isValidRfc.value;
                    }
                    else if ( paternalName.length <= 2 ) {
                         Swal.fire({
                            title: "Aviso",
                            html: "El  nombre, apellidos o fecha de nacimiento no coincide con el RFC generado, verifique los datos ingresados",
                            icon: "warning",
                            confirmButtonText: "OK",
                            confirmButtonColor: '#FEB72B'
                        });

                        isValidRfc.value = false;
                        isActive.value = isValidRfc.value;

                    }
                    
                }
             }
        })

        const getTokenAuthorization = async () => {
            return await quoteClass.value.getToken().then(resp => resp.data.response.token_auth)
        }

        const searchCatalogInQuestion = async (listQuestion) => {
            if(listQuestion.depend_on_response!=""){
                await searchInCatalog(listQuestion)
            }

            if(listQuestion.depend_on_response == "" && listQuestion.question_relationship_id == 0){
                await searchInCatalog(listQuestion);
            }
        }

        const searchInCatalog = async (listQuestion) => {
            try{
                isLoading.value = true;
                const res = await get(`${listQuestion.endpoints_catalog[0].endpoint}`, { headers: {"Authorization" : `Token ${tokenAuthorization.value}`}, params:  listQuestion.endpoints_catalog[0].json_params.body}).then((response) => response);
                if(res.status == 200){
                    if(!res.data.response.has_errors){
                        listQuestion.options = res.data.response.data.map((response, index)=>{
                            return {
                                "option": response.name,
                                "status": "Activo",
                                "testing_question_id": `${listQuestion.question_type}_${currentCategory.value}_${index}`,
                                "name_option": response.name,
                                "option_id": `${listQuestion.question_type}_${currentCategory.value}_${index}`,
                                "isActive": false,
                                "activeClass": "active"
                            }
                        });
                    }
                }
                isLoading.value = false;
            }
            catch(ex){
                console.log(ex);
                isLoading.value = false;
                Swal.fire({
                    icon:'error',
                    title:"Cuidado!",
                    text:"Ocurrió un error inesperado."
                }).then((res)=>{
                    if(res){
                        router.push({ path: "/cuenta/principal" });
                    }
                })
            }
        }

        const getQuestions = async () => {
            
            const response = await productsClass.value.getVsQuestions(sessionStorage.getItem("login")).then(resp => resp.data.response.response ).catch(err => { 
                if(err.response.status != 500) {
                    Swal.fire({
                        title: "Aviso",
                        html: err.response.data.response.message,
                        icon: "info",
                        confirmButtonText: "OK",
                        confirmButtonColor: '#FEB72B'
                    });
                }else{
                    Swal.fire({
                        title: "Aviso",
                        html: "Servicio de cotización no disponible, por favor inténtelo de nuevo más tarde",
                        icon: "info",
                        confirmButtonText: "OK",
                        confirmButtonColor: '#FEB72B'
                    });
                }

                return null;
            });
            
            if(response == null){
                router.push({path: '/cuenta/detalle/vida-simple'})
                return;
            }
            
            let inProgress = getCategoryInformation(response.order_question_category, true);
            if(inProgress.status){
                indexCategory.value = inProgress.position;
            }

            let questionList = [];
            
            currentCategory.value = (inProgress.status)?inProgress.category.node_key :response.order_question_category[indexCategory.value].node_key;
            questionTitle.value = (inProgress.status)?inProgress.category.title :response.order_question_category[indexCategory.value].title;

            allQuestionsList.value = response;
            questionList = (inProgress.status)? inProgress.questions :response.questions[currentCategory.value];
            
            questionList = setOptionType(questionList, !inProgress.status);
            
            questionsList.value = questionList;

            getValidations(currentCategory.value);
            getImageCategory(currentCategory.value);
        }

        const setOptionType = (questions, updateResponse = false) => {
            questions.forEach( question => {
                question.errors = [];
                if(updateResponse){
                    question.response = (question.question_type == 'textarea' || question.question_type == 'checkbox' || question.question_type == 'file')? [] : (question.question_type == 'number')? 0 : '';
                }
                question.is_touched = false;
            });

            return questions;
        }

        const getCategoryInformation = (categories, firstCategory) => {
            try{
                if(storageExist('quote')){
                    let quote = getStorage('quote');
                    const activeCategories = categories.filter(category => quote[category.node_key] != undefined)
                    let i = Object.keys(activeCategories);
                    
                    if(i.length == 0){
                        throw '¡Llaves no encontradas!';
                    }

                    if(firstCategory){
                        i = i.shift(); 
                    }else{
                        i = i.pop();
                    }

                    return {
                        status: true,
                        position: parseInt(i),
                        category: activeCategories[i],
                        questions: quote[activeCategories[i].node_key]
                    }
                }else{
                    return {
                        status: false,
                        position: 0
                    }
                }
            }catch(err){
                console.log("Error getCategoryInformation:", err)
                return {
                    status: false,
                    position: 0
                }
            }
        }  

        const getImageCategory = (category) => {
            imageCategory.value = require(`@/assets/Cuenta/vidaSimple/${category}.svg`);
        }

        const next = async ()   => {
            // console.log(isValidRfc.value);
            //   if ( !isValidRfc.value ) {
            //     Swal.fire({
            //         title: "Aviso",
            //         html: "El  nombre, apellidos o fecha de nacimiento no coincide con el RFC generado, verifique los datos ingresados!!.",
            //         icon: "info",
            //         confirmButtonText: "OK",
            //         confirmButtonColor: '#FEB72B'
            //     });
            //     return;
            // }

            if(!isActive.value){
                return;
            }

            if(indexCategory.value >= allQuestionsList.value.order_question_category.length-1){
                router.push({path:"/cuenta/detalle/vida-simple/solicitante"});
                return;
            }
            
            let navigation = skipCategory();
        
            if(navigation.status){
                indexCategory.value = navigation.data.next;
            }else{
                indexCategory.value++;//Flujo normal
            }

            currentCategory.value = allQuestionsList.value.order_question_category[indexCategory.value].node_key;
            questionTitle.value = allQuestionsList.value.order_question_category[indexCategory.value].title;

            let exist = storageExist('quote');
            let quoteCategoryExist = {};
            if (exist) {
                let quote = getStorage('quote');
                quoteCategoryExist = quote.hasOwnProperty(currentCategory.value);
            }

            if (!quoteCategoryExist){ 
                getValidations(currentCategory.value);
                
                questionsList.value = allQuestionsList.value.questions[currentCategory.value];
                questionsList.value = setOptionType(questionsList.value, true);  
            } else {
                getValidations(currentCategory.value);
                setData();
            }
            
            reRender.value = !reRender.value;
            getImageCategory(currentCategory.value);
            isActive.value = false; 
        }

        const skipCategory = () => {
            try{
                let nav = {
                    status: false,
                    next: 0,
                    back: 0
                };

                if(questionTitle.value.toString().toLowerCase() ==  'datos de contratación'){

                    if(changeInFlow.value){
                        let storageQuote = clearStorageInformation('quote', 'contracting_data');
                        let storageQuoteData = clearStorageInformation('quote-data', 'contracting_data');
                        
                        createStorage('quote', storageQuote.data);
                        createStorage('quote-data', storageQuoteData.data);
                        removeStorage('issuance');
                        removeStorage('issuance-data');
                    }
                    
                    let quoteData = getStorage('quote-data');
                    if(quoteData.contracting_data.contractor_other_than_the_applicant == 'si'){
                        nav.status = true;
                        router.push({path: '/cuenta/cotizacion/vida-simple/asegurado-adicional'})
                    }
                }

                if(questionTitle.value.toString().toLowerCase() ==  'deportes'){
                    nav.status = true;
                    nav.next = 5;
                    nav.back = 2;
                }

                return {
                    status: nav.status,
                    data: nav,
                    message: 'Navegacion con excepcion encontrada'
                }

            }catch(err){
                return {
                    status: false,
                    data: null,
                    message: err
                }
            }

        }

        const back = ()   => {
           
            if(indexCategory.value == 0){
                router.push({path: `/cuenta/cotizacion/vida-simple/bienvenida`})
                return;
            }
        
            indexCategory.value--;
            currentCategory.value = allQuestionsList.value.order_question_category[indexCategory.value].node_key;
            questionTitle.value = allQuestionsList.value.order_question_category[indexCategory.value].title;
            
            getValidations(currentCategory.value);    
            reRender.value = !reRender.value;

            setData();
            getImageCategory(currentCategory.value);
        }
        
        const changeModelPersonalData = (values) => {

            changeInFlow.value = changesInFlowOfQuestionsByContractor(values);

            userInfo.value = values;
            let exist = storageExist('quote');
            
            if(!exist) {
                data.value[currentCategory.value] = questionsList.value;
                createStorage('quote', data);
            } else {
                if(!isEmptyObject(userInfo.value)) {
                    const quote = getStorage('quote');
                    quote[currentCategory.value] = questionsList.value;
                    createStorage('quote', quote);
                }
            }

            let existQuoteData = storageExist('quote-data');
            if (!existQuoteData) {
                quoteData.value[currentCategory.value] = userInfo.value;
                createStorage('quote-data', quoteData);
            }else{
                if(!isEmptyObject(userInfo.value)) {
                    const quoteDetail = getStorage('quote-data');
                    quoteDetail[currentCategory.value] = userInfo.value;
                    createStorage('quote-data', quoteDetail);
                }
            }

            isActive.value = !isEmptyObject(userInfo.value);

       

            //TODO modificar para optimizar código
            setTimeout(()=>{
                disabledCheckboxCoverage();
            }, 300)
        }

        const changesInFlowOfQuestionsByContractor = (value) => {
            
            let exist = storageExist('quote-data')
            if(typeof value.contractor_other_than_the_applicant !== 'undefined' && exist){
                let quoteData = getStorage('quote-data');
                if(!quoteData.hasOwnProperty('contracting_data')){
                    return false;
                }

                if(quoteData.contracting_data.contractor_other_than_the_applicant !== value.contractor_other_than_the_applicant){
                    return true;
                }
            }

            return false;
        }

        const disabledCheckboxCoverage = () => {
            if(document.getElementById('check_button_coverage_ima_coverages')!= null) {

                var ima = document.getElementById('check_button_coverage_ima_coverages');
                var imapo = document.getElementById('check_button_coverage_imapo_coverages');
                var dimapo = document.getElementById('check_button_coverage_dimapo_coverages');
                
                if( ima.checked ){
                    imapo.disabled = true;
                    dimapo.disabled = true;
                    imapo.checked = false;
                    dimapo.checked = false;
                } 
                else if ( !ima.checked && imapo.disabled && dimapo.disabled) {
                    imapo.disabled = false;
                    dimapo.disabled = false;
                }
                else if(imapo.checked ){
                    ima.checked = false;
                    ima.disabled = true;
                    dimapo.disabled = true;
                    dimapo.checked = false;
                }
                else if (!imapo.checked && ima.disabled && dimapo.disabled) {
                    ima.disabled = false;
                    dimapo.disabled = false;
                }
                else if( dimapo.checked ){
                    ima.checked = false;
                    ima.disabled = true;
                    imapo.disabled = true;
                    imapo.checked = false;
                } 
                else if (!dimapo.checked && imapo.disabled && ima.disabled) {
                    ima.disabled = false;
                    ima.checked = false;
                    imapo.disabled = false;
                    imapo.disabled = false;
                }
            
            }
        }

        const setData = () => {
            let quoteQuestions = getStorage('quote');
            questionsList.value = quoteQuestions[currentCategory.value];
        }

        const clearStorageInformation = (key, nodeKeyException = "") => {
            try{
                if(storageExist(key)){

                    let storage = getStorage(key);
                    Object.keys(storage).forEach((node) => {
                        if(node !== nodeKeyException){
                            delete storage[node];
                        }
                    });
                    
                    return {
                        status: true,
                        message: 'Limpiaza de almacenamiento realizado',
                        data: storage
                    };
                }
                
                throw "Llave no encontrada en almacenamiento";
                
            }catch(err){
                return {
                    status: false,
                    message: err,
                    data: {}
                };
            }
            
        }
        const removeStorage = (name) => {
            localStorage.removeItem(name);
        }

        const createStorage = (name, value) => {
            localStorage.setItem(name, JSON.stringify(value));
        }
        
        const getStorage = (name) => {
            return JSON.parse(localStorage.getItem(name));
        }

        const storageExist = (name) => {
            return (localStorage.getItem(name))? true: false;
        }

        function isEmptyObject(obj) {
            return Object.keys(obj).length === 0;
        }

        const validityRFC =  async ( rfc, birthday, name, middleName, paternalName, maternalName ) => {
            let validRfc = false;
            
            let resp = await validateMatchRFCwithName(rfc, birthday, name, middleName, paternalName, maternalName ).then(response => response).catch(( error )=> {
                if (error.response.status == 422) {
                    Swal.fire({
                        title: "Aviso",
                        html: `${error.response.data.errors[0].replace('rfc', 'RFC')}`,
                        icon: "error",
                    });

                    validRfc = false;
                }

            });

            if (resp != null ) {
                if (resp.status == 200 ) {
                    validRfc = true;
                }
            }

            return validRfc;
        }

        const validation_match_rfc_and_curp = (rfc,curp,dateBirth) => {
            const date = userClass.generate_structure_date_and_rfcType(dateBirth);
            return (rfc.substr(0,10)==curp.substr(0,10)) && date==(curp.substr(4,6));
        }
        
        const getValidations =  (category) => {   
             
            let validations = {};
            let model = {};

            if (category == "contracting_data") {
                validations = {         
                    contractor_other_than_the_applicant: {
                        required: helpers.withMessage(ValidationsStructure.contracting_data.contractor_other_than_the_applicant.required.message, 
                        requiredIf(true)
                        // function(data,index) {                         
                        //     if(index.contractor_other_than_the_applicant=="si"){
                        //         if(index.relationship_to_applicant==""){
                        //             return false;
                        //         }
                        //         else{
                        //             return true;
                        //         }
                        //     }
                        //     else {
                        //         return true;
                        //     }
                        // }
                        )
                    },
                    relationship_to_applicant: {
                        required: helpers.withMessage(ValidationsStructure.contracting_data.relationship_to_applicant.required.message, requiredIf(function (value, index){
                            if(index.contractor_other_than_the_applicant == "si"){
                                return ValidationsStructure.contracting_data.relationship_to_applicant.required.value;
                            }
                            return false;
                        }))
                    },
                    input_rfc: {
                        required: helpers.withMessage(ValidationsStructure.contracting_data.input_rfc.required.message, function(data,index) { 
                            if(index.contractor_other_than_the_applicant=="si"){
                                if(index.input_rfc==""){
                                    return false;
                                }
                            }
                            return true;
                        }),
                        strucValidationRFC: helpers.withMessage("El formato del R.F.C no es correcto. Ejemplo de formato correcto: LOMP8206281H0", function (data,index){
                            if(index.contractor_other_than_the_applicant=="si"){
                                const rfc_pattern =
                                    "^(([A-ZÑ&]{4})([0-9]{2})([0][13578]|[1][02])(([0][1-9]|[12][\\d])|[3][01])([A-Z0-9]{3}))|" +
                                    "(([A-ZÑ&]{4})([0-9]{2})([0][13456789]|[1][012])(([0][1-9]|[12][\\d])|[3][0])([A-Z0-9]{3}))|" +
                                    "(([A-ZÑ&]{4})([02468][048]|[13579][26])[0][2]([0][1-9]|[12][\\d])([A-Z0-9]{3}))|" +
                                    "(([A-ZÑ&]{4})([0-9]{2})[0][2]([0][1-9]|[1][0-9]|[2][0-8])([A-Z0-9]{3}))$";
                                    
                                const coincidences = index.input_rfc.toUpperCase().match(rfc_pattern);
                                const dataE = [];

                                if(coincidences!=null){
                                    coincidences.forEach((element) => {
                                        if(typeof element != 'undefined'){
                                            dataE.push(element);
                                        }
                                    });
                                }

                                return (coincidences == null ) ? false : true;
                            }
                            return true;
                        }),
                        rfcService: helpers.withMessage("RFC no coincide con los parametros enviados!!",  helpers.withAsync( async function (value, index) {
                            
                            if(index.contractor_other_than_the_applicant == "si"){
                                let rfc = ( index.input_rfc != '' ) ? index.input_rfc.length : 0;
                                let name = (index.input_nombre_contracting_data != '' ) ? index.input_nombre_contracting_data.length : 0;
                                let paternalName = ( index.input_paternal_name_contracting_data != '') ? index.input_paternal_name_contracting_data.length : 0;
                                let maternalName = ( index.input_maternal_name_contracting_data != '') ? index.input_maternal_name_contracting_data.length : 0;
                                let birthdate = (index.birthdate_contracting_data != '' ) ? index.birthdate_contracting_data.length : 0;

                                if ( rfc == 13 && name > 2  && paternalName > 2 && birthdate > 0 ) {
                                    // isValidRfc.value = await validityRFC(rfc, birthdate, name, "", paternalName, maternalName );
                                    isValidRfc.value = await validityRFC(index.input_rfc , index.birthdate_contracting_data, index.input_nombre_contracting_data, "", index.input_paternal_name_contracting_data , (maternalName>0?index.input_maternal_name_contracting_data:'')  );

                                    return isValidRfc.value;
                                }
                            }
                            return true;
                        })),
                        minlength: helpers.withMessage(ValidationsStructure.contracting_data.input_rfc.minLength.message, function (value, index){                            
                            if(index.contractor_other_than_the_applicant == "si"){
                                if (index.input_rfc.length < ValidationsStructure.contracting_data.input_rfc.minLength ) {
                                    return false;
                                }
                            }
                            return true;
                        }),
                        maxLength: helpers.withMessage("El campo debe contener mínimo 13 caracteres", maxLength(13)),
                        validation_match_rfc_and_curp:helpers.withMessage("El campo R.F.C, C.U.R.P y Fecha de nacimiento no coinciden.",function(data,index){

                            if(index.contractor_other_than_the_applicant == "si"){
                                if(index.curp_contracting_data.length==18 && index.input_rfc.length==13 && index.birthdate_contracting_data.length){
                                    return validation_match_rfc_and_curp(index.input_rfc,index.curp_contracting_data,index.birthdate_contracting_data)
                                }
                            }
                            return true;
                        })
                    },
                    curp_contracting_data: {
                        required: helpers.withMessage(ValidationsStructure.contracting_data.curp_contracting_data.required.message, requiredIf(function (value, index){
                            if(index.contractor_other_than_the_applicant == "si"){
                                return ValidationsStructure.contracting_data.curp_contracting_data.required.value;
                            }

                            return !ValidationsStructure.contracting_data.curp_contracting_data.required.value;
                        })),
                        strucValidationCURP: helpers.withMessage("El formato del C.U.R.P no es correcto. Ejemplo de formato correcto: LOMP870719MSRLLR01.", function(data,index){
                            if(index.contractor_other_than_the_applicant=="si"){
                                const curp_pattern =
                                    /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/;
                                const coincidences = index.curp_contracting_data.toUpperCase().match(curp_pattern);
                                return coincidences == null ? false : true;
                            }
                            return true;
                            
                        }),
                        minLength: helpers.withMessage("El campo debe contener mínimo 18 caracteres.", minLength(18)),
                        maxLength: helpers.withMessage("El campo debe contener mínimo 18 caracteres.", maxLength(18)),
                        validation_match_rfc_and_curp:helpers.withMessage("El campo R.F.C, C.U.R.P y Fecha de nacimiento no coinciden.",function(data,index){

                             if(index.contractor_other_than_the_applicant == "si"){
                                if(index.curp_contracting_data.length==18 && index.input_rfc.length==13 && index.birthdate_contracting_data.length>0){
                                    return validation_match_rfc_and_curp(index.input_rfc,index.curp_contracting_data,index.birthdate_contracting_data)
                                }
                            }
                            return true;
                        })
                    },
                    input_nombre_contracting_data: {
                        required: helpers.withMessage(ValidationsStructure.contracting_data.input_nombre_contracting_data.required.message, requiredIf(function (value, index){
                            if(index.contractor_other_than_the_applicant == "si"){
                                return ValidationsStructure.contracting_data.input_nombre_contracting_data.required.value;
                            }

                            return !ValidationsStructure.contracting_data.input_nombre_contracting_data.required.value;
                        })),
                        minlength: helpers.withMessage(ValidationsStructure.contracting_data.input_nombre_contracting_data.minLength.message, function (value, index){                            
                            if(index.contractor_other_than_the_applicant == "si"){
                                if (index.input_nombre_contracting_data != " ") {
                                    if (index.input_nombre_contracting_data.length < 2 ) {
                                        return false;
                                    } else {
                                        return true;
                                    }
                                }
                                
                            }
                            return true;
                        }),
                        alpha: helpers.withMessage(ValidationsStructure.contracting_data.input_nombre_contracting_data.alpha.message, function (data, index) {
                             if(index.contractor_other_than_the_applicant == "si"){ 
                                 if (index.input_nombre_contracting_data != "") {
                                     return new RegExp(/^[a-zA-ZñÑÀ-ÿ\s\-]+$/).test(index.input_nombre_contracting_data);
                                 }
                             } else {
                                 return true;
                             }
                        }),
                        we_have_space_white_start_and_finish:helpers.withMessage(`El campo no puede tener espacios en blanco al inicio y al final.`,(data,index)=>index.input_nombre_contracting_data != ""? !new RegExp(/^([\s]{1,})|[-!$%^&*()_+|~=`{}[]:";'<>?,.\/]|([\s]{1,})$/).test(index.input_nombre_contracting_data): true)
                    },
                    input_paternal_name_contracting_data: {
                        required: helpers.withMessage(ValidationsStructure.contracting_data.input_paternal_name_contracting_data.required.message, requiredIf(function (value, index){
                            if(index.contractor_other_than_the_applicant == "si"){
                                return ValidationsStructure.contracting_data.input_paternal_name_contracting_data.required.value;
                            }

                            return !ValidationsStructure.contracting_data.input_paternal_name_contracting_data.required.value;
                        })),
                        minlength: helpers.withMessage(ValidationsStructure.contracting_data.input_paternal_name_contracting_data.minLength.message, function (value, index){                            
                            if(index.contractor_other_than_the_applicant == "si"){
                                if (index.input_paternal_name_contracting_data != " ") { 
                                    if (index.input_paternal_name_contracting_data.length < 2 ) {
                                        return false;
                                    } else {
                                        return true;
                                    }
                                }      
                            }
                            return true;
                        }),
                        alpha: helpers.withMessage(ValidationsStructure.contracting_data.input_paternal_name_contracting_data.alpha.message, function (data, index) {
                             if(index.contractor_other_than_the_applicant == "si"){ 
                                 if (index.input_paternal_name_contracting_data != "") {
                                     return new RegExp(/^[a-zA-ZñÑÀ-ÿ\s\-]+$/).test(index.input_paternal_name_contracting_data);
                                 }
                             } else {
                                 return true;
                             }
                        }),
                        we_have_space_white_start_and_finish: helpers.withMessage(`El campo no puede tener espacios en blanco al inicio y al final.`,(data,index)=>index.input_paternal_name_contracting_data != ""? !new RegExp(/^([\s]{1,})|[-!$%^&*()_+|~=`{}[]:";'<>?,.\/]|([\s]{1,})$/).test(index.input_paternal_name_contracting_data): true)
                    },
                    input_maternal_name_contracting_data: {
                        required: helpers.withMessage(ValidationsStructure.contracting_data.input_maternal_name_contracting_data.required.message, function (value, index){
                            if(index.contractor_other_than_the_applicant == "si"){
                                return ValidationsStructure.contracting_data.input_maternal_name_contracting_data.required.value;
                            }
                            return true;
                        }),
                        minlength: helpers.withMessage(ValidationsStructure.contracting_data.input_maternal_name_contracting_data.minLength.message, function (value, index){                            
                            if(index.contractor_other_than_the_applicant == "si"){
                                 if (index.input_maternal_name_contracting_data != "") {  
                                    if (index.input_maternal_name_contracting_data.length < 2 ) {
                                        return false;
                                    } else {
                                        return true;
                                    }
                                 }
                            }
                            return true;
                        }),
                        alpha: helpers.withMessage(ValidationsStructure.contracting_data.input_maternal_name_contracting_data.alpha.message, function (data, index) {
                             console.log("entre en quote")
                            if(index.contractor_other_than_the_applicant == "si"){
                                if (index.input_maternal_name_contracting_data != "") {
                                    return !new RegExp(/[^a-zA-ZÀ-ÿ@\s-]+/).test(index.input_maternal_name_contracting_data);
                                }
                            }
                    
                            return true; 
                        }),
                        we_have_space_white_start_and_finish:helpers.withMessage(`El campo no puede tener espacios en blanco al inicio y al final.`,(data,index)=>index.input_maternal_name_contracting_data != ""? !new RegExp(/^([\s]{1,})|[-!$%^&*()_+|~=`{}[]:";'<>?,.\/]|([\s]{1,})$/).test(index.input_maternal_name_contracting_data): true)
                    },
                    birthdate_contracting_data: {
                        required: helpers.withMessage(ValidationsStructure.contracting_data.birthdate_contracting_data.required.message, requiredIf(function (value, index){
                            if(index.contractor_other_than_the_applicant == "si"){
                                return ValidationsStructure.contracting_data.birthdate_contracting_data.required.value;
                            }

                            return false;
                        })),
                        date_select_is_future: helpers.withMessage(`La fecha selecionada no es permitida.`, function(data,index) { 
                            if (index.contractor_other_than_the_applicant == "si") {
                                let startDate = moment(data, 'DD/MM/YYYY').format('YYYY-MM-DD');
                                let currentDate =  moment().format("YYYY-MM-DD");
                                     
                                    if( startDate >= currentDate ){
                                        return !ValidationsStructure.contracting_data.birthdate_contracting_data.date.value;
                                    }
                                    else{
                                        return ValidationsStructure.contracting_data.birthdate_contracting_data.date.value;
                                    }
                            } else {
                                return true;
                            }
                        }),
                        validation_match_rfc_and_curp:helpers.withMessage("El campo R.F.C, C.U.R.P y Fecha de nacimiento no coinciden.",function(data,index){

                            if(index.contractor_other_than_the_applicant == "si"){
                                if(index.curp_contracting_data.length==18 && index.input_rfc.length==13 && index.birthdate_contracting_data.length){
                                    return validation_match_rfc_and_curp(index.input_rfc,index.curp_contracting_data,index.birthdate_contracting_data)
                                }
                            }
                            return true;
                        })
                    },
                    birthplace_contracting_data:{
                        required: helpers.withMessage('El campo es requerido', requiredIf(function (value, index){
                            if(index.contractor_other_than_the_applicant == "si"){
                                return true;
                            }

                            return false;
                        }))
                    },
                    height: {
                        required: helpers.withMessage(ValidationsStructure.contracting_data.height.required.message, function (value, index){
                            if(index.contractor_other_than_the_applicant == "si"){
                                return ValidationsStructure.contracting_data.height.required.value;
                            }

                            return true;
                        }),
                        minLenght: helpers.withMessage(ValidationsStructure.contracting_data.height.min.message, function(value, index){
                            let height = Number(index.height);
                            if (index.contractor_other_than_the_applicant == "si") {
                                if(height > 0){
                                    return true;
                                }
                            }

                            return true;
                        }),
                        numberFormat: helpers.withMessage(ValidationsStructure.contracting_data.height.numberFormat.message, function (data, index) {
                            if (index.contractor_other_than_the_applicant == "si") {
                                return new RegExp(/^[0-9]+([.][0-9]{1,2})?$/).test(index.height.toString());
                            }
                            return true
                        }),
                        maxheigth:helpers.withMessage(`La altura maxima es 2.52 mts.`, function(data,index){
                            if (index.contractor_other_than_the_applicant == "si") {
                                if(index.height>2.52){
                                    return false
                                }
                                if(index.height==0){
                                    return false
                                }
                            }
                            return true
                        }),
                        minValue: helpers.withMessage("La altura mínima es 1.20 mts", function(value, index){
                            if (index.contractor_other_than_the_applicant == "si") {                                
                                if(index.height < 1.20){
                                    return false;
                                }
                            }

                            return true;
                        }),

                    },
                    weight: {
                        required: helpers.withMessage(ValidationsStructure.contracting_data.weight.required.message, requiredIf(function (value, index){
                            if(index.contractor_other_than_the_applicant == "si"){
                                return ValidationsStructure.contracting_data.weight.required.value
                            }

                            return false;
                        })),
                        minLenght: helpers.withMessage("Debe ingresar por lo menos dos dígitos", function(value, index){
                            if (index.contractor_other_than_the_applicant == "si") {                                
                                if(index.weight.toString().length < 2){
                                    return false;
                                }
                            }

                            return true;
                        }),
                        minValue: helpers.withMessage("El peso minímo permitido son 40 kg", function(value, index){
                            if (index.contractor_other_than_the_applicant == "si") {                                
                                if(index.weight < 40){
                                    return false;
                                }
                            }

                            return true;
                        }),
                        maxLenght: helpers.withMessage("Debe ingresar máximo tres dígitos", function(value, index){
                            if (index.contractor_other_than_the_applicant == "si") {
                                if(index.weight.toString().length > 3){
                                    return false;
                                }
                            }

                            return true;
                        }),
                        maxValue: helpers.withMessage("El peso máximo permitido son 200 kg", function(value, index){
                            if (index.contractor_other_than_the_applicant == "si") {                                
                                if(index.weight > 200){
                                    return false;
                                }
                            }

                            return true;
                        }),
                        numberFormat: helpers.withMessage("Solo se permiten números enteros", function (data, index) {
                            if (index.contractor_other_than_the_applicant == "si") {
                                return new RegExp(/^[0-9]+$/).test(index.weight.toString());
                            }

                            return true
                       })
                    },
                    gender_contracting_data: {
                        required: helpers.withMessage(ValidationsStructure.contracting_data.gender_contracting_data.required.message, requiredIf(function (value, index){
                            if(index.contractor_other_than_the_applicant == "si"){
                                return ValidationsStructure.contracting_data.gender_contracting_data.required.value;
                            }

                            return false;
                        }))                    
                    },
                    marital_status_contracting_data:{
                        required: helpers.withMessage(ValidationsStructure.contracting_data.marital_status_contracting_data.required.message, requiredIf(function (value, index){
                            if(index.contractor_other_than_the_applicant == "si"){
                                return ValidationsStructure.contracting_data.marital_status_contracting_data.required.value;
                            }

                            return false;
                        }))
                    } 
                }

                model = {         
                    contractor_other_than_the_applicant: "",
                    relationship_to_applicant: "",
                    input_rfc: "",
                    curp_contracting_data: "",
                    input_nombre_contracting_data: "",
                    input_paternal_name_contracting_data: "",
                    input_maternal_name_contracting_data: "",
                    birthdate_contracting_data: "",
                    height: "",
                    weight: "",
                    gender_contracting_data: "",
                    marital_status_contracting_data: "",
                    birthplace_contracting_data: ""
                }        
            }

            if (category == "personal_info") {
                validations = {         
                    height: {
                        required: helpers.withMessage(ValidationsStructure.personal_info.height.required.message, requiredIf(ValidationsStructure.personal_info.height.required.value)),
                        minLenght: helpers.withMessage(ValidationsStructure.personal_info.height.min.message, function(value, index){
                            let height = Number(index.height);
                            if(height > 0){
                                return true;
                            }

                            return false;
                        }),
                        numberFormat: helpers.withMessage(ValidationsStructure.personal_info.height.numberFormat.message, function (data, index) {
                            if (index.height != '' ) {
                                 return new RegExp(/^[0-9]+([.][0-9]{1,2})?$/).test(index.height.toString());
                            }
                            else {
                                return true;
                            }
                        }),
                        maxHeigth:helpers.withMessage(`La altura maxima es 2.52 mts.`, function(data,index){
                            if(index.height > 2.52){
                                return false
                            }

                            return true
                        })
                    },
                    weight: {
                        required: helpers.withMessage(ValidationsStructure.personal_info.weight.required.message, requiredIf(ValidationsStructure.personal_info.weight.required.value)),
                        valueTwoNumber:helpers.withMessage("Después del punto solo se admiten 2 decimales.",function(data,index) {
                            if(index.weight!=""){
                                return new RegExp(/^[0-9]+([.][0-9]{1,2})?$/).test(index.weight.toString())
                            }
                            else return true;
                        }),
                        maximum_weight: helpers.withMessage(`El peso maximo son 200 Kg`, function(data,index){
                            if(index.weight!=""){
                                return parseFloat(index.weight) <= 200
                            }
                            else{
                                return true
                            }
                            
                        }),
                        minimun_weight: helpers.withMessage(`El peso mínimo son 40 Kg`, function(data,index){
                            if(index.weight!=0){
                                return parseFloat(index.weight) >= 40
                            }
                            if(index.weight==0){
                                return false
                            }
                            else{
                                return true
                            }
                            
                        })
                    }
                }

                model = {         
                    height: "",
                    weight: ""
                }        
            }

            if (category == "diseases") {
                validations = {
                    suffer_from_disease: {
                        required: helpers.withMessage(ValidationsStructure.diseases.suffer_from_disease.required.message, 
                        requiredIf(ValidationsStructure.diseases.suffer_from_disease.required.value))
                    },
                    diseases_list: {
                        required: helpers.withMessage(ValidationsStructure.diseases.diseases_list.required.message, requiredIf(ValidationsStructure.diseases.diseases_list.required.value))
                    },
                    diseases: {
                        // Cuando se usa function el required funciona a la inversa, es decir si el campo es requerido se devuelve un false si no se devuelve un true
                        required: helpers.withMessage(ValidationsStructure.diseases.diseases.required.message, function (data, i ) {
                            // Validar que sea requerido cuando el 1er nodo se igual a sí
                            if (i.suffer_from_disease == "si" ) {
                                // Validar si por lo menos hay un elemento en la lista (return true) entonces significa que no será requerido
                                // si no hay ningun elemento en la lista (return false) el campo es requerido
                                if (i.diseases.length > 0){
                                    return ValidationsStructure.diseases.diseases.required.value;
                                } else {
                                    return !ValidationsStructure.diseases.diseases.required.value;
                                }
                            } else {
                                return ValidationsStructure.diseases.diseases.required.value;
                            }
                        })
                    },
                    suffer_from_other_disease: {
                        required: helpers.withMessage(ValidationsStructure.diseases.suffer_from_other_disease.required.message, function (data, i ) {
                        
                            if (i.suffer_from_disease == "si" ) { 
                                if (i.suffer_from_other_disease != "") {
                                    return ValidationsStructure.diseases.suffer_from_other_disease.required.value;
                                }     
                            } else {
                                return ValidationsStructure.diseases.suffer_from_other_disease.required.value;
                            }
                        })
                    },
                    other_diseases_list: {
                        required: helpers.withMessage(ValidationsStructure.diseases.other_diseases_list.required.message, requiredIf(ValidationsStructure.diseases.other_diseases_list.required.value))
                    },
                    other_diseases: {
                        required: helpers.withMessage(ValidationsStructure.diseases.other_diseases.required.message, function (data, i ) {
                            if (i.suffer_from_other_disease == "si" ) {
                                if (i.other_diseases.length > 0) {
                                    return !ValidationsStructure.diseases.other_diseases.required.value;
                                } else {
                                    return ValidationsStructure.diseases.other_diseases.required.value;
                                }
                            } else {
                                return !ValidationsStructure.diseases.other_diseases.required.value;
                            }
                        })
                    }
                }   
                model = {
                    suffer_from_disease:"",
                    diseases_list: "",
                    diseases:"",
                    suffer_from_other_disease: "",
                    other_diseases_list: "",
                    other_diseases: ""
                }  
            }

            if (category == "sports") {
                validations = {
                    practice_sports: {
                        required: helpers.withMessage(ValidationsStructure.sports.practice_sports.required.message, requiredIf(ValidationsStructure.sports.practice_sports.required.value))             
                    },
                    sports: {
                        required: helpers.withMessage(ValidationsStructure.sports.sports.required.message, requiredIf((data, i) => i.practice_sports == "si"))             
                    },
                    sports_list: {
                        required: helpers.withMessage(ValidationsStructure.sports.sports_list.required.message, requiredIf(ValidationsStructure.sports.sports_list.required.value))
                    }
                }

                model = {
                    practice_sports: "",
                    sports: "",
                    sports_list: ""
                }
            }

            if (category == 'profession_occupation') {
                validations = {
                    occupation: {
                        required: helpers.withMessage(ValidationsStructure.profession_occupation.occupation.required.message, requiredIf(ValidationsStructure.profession_occupation.occupation.required.value))             
                    },
                    occupation_list: {
                        required: helpers.withMessage(ValidationsStructure.profession_occupation.occupation_list.required.message, requiredIf(ValidationsStructure.profession_occupation.occupation_list.required.value))
                    }
                }

                model = {
                    occupation: "",
                    occupation_list: ""
                }
            }

            if ( category == "operations") {
                validations = {   
                    have_you_had_an_operation : {
                        required: helpers.withMessage(ValidationsStructure.operations.have_you_had_an_operation.required.message, requiredIf(ValidationsStructure.operations.have_you_had_an_operation.required.value))
                    },
                    operations_list: {
                        required: helpers.withMessage(ValidationsStructure.operations.operations_list.required.message, requiredIf(ValidationsStructure.operations.operations_list.required.value))
                    }, 
                    operations: {
                        required: helpers.withMessage(ValidationsStructure.operations.operations.required.message, requiredIf((data, i) => {
                          if (i.have_you_had_an_operation == "si") {
                              if (i.operations.length > 0) {
                                  return !ValidationsStructure.operations.operations.required.value;
                              } else {
                                  return ValidationsStructure.operations.operations.required.value;
                              }
                          } else {
                              return !ValidationsStructure.operations.operations.required.value;
                          }
                        }))
                    }
                }

                model = {
                    have_you_had_an_operation: "",
                    operations_list: "",
                    operations: ""
                }   
            }

            if ( category == "hobbies") {
                validations = {   
                    have_hobbies: {
                        required: helpers.withMessage(ValidationsStructure.hobbies.have_hobbies.required.message, requiredIf(ValidationsStructure.hobbies.have_hobbies.required.value)),
                    },
                    hobbies_list: {
                        required: helpers.withMessage(ValidationsStructure.hobbies.hobbies_list.required.message, requiredIf(ValidationsStructure.hobbies.hobbies_list.required.value)),
                    }, 
                    hobbies: {
                        required: helpers.withMessage(ValidationsStructure.hobbies.hobbies.required.message, requiredIf((data, i) => {
                          if (i.have_hobbies == "si") {
                              if (i.hobbies.length > 0) {
                                  return !ValidationsStructure.hobbies.hobbies.required.value;
                              } else {
                                  return ValidationsStructure.hobbies.hobbies.required.value;
                              }
                          } else {
                              return !ValidationsStructure.hobbies.hobbies.required.value;
                          }
                        }))
                    }
                }

                model = {
                    have_hobbies: "",
                    hobbies_list: "",
                    hobbies: ""
                }   
            }

            if (category == "smoke") {
                  validations = {   
                    smoke_tobacco: {
                        required: helpers.withMessage("El campo es requerido.", requiredIf(function () { return true})),
                    },
                    tobacco_type_list: {
                        required: helpers.withMessage("El campo es requerido.", function (data, index) {
                            if(index.smoke_tobacco=="si"){
                                if(index.tobacco_type_list!=""){
                                    return true
                                }
                                else{
                                    return false
                                }
                            }
                            else{       
                                return true
                            }
                        }),
                    },
                    tobacco_types: {
                        required: helpers.withMessage("El campo es requerido.", function (data, index) {
                            if(index.smoke_tobacco=="si"){
                                if(index.tobacco_types.toString()!=""){
                                    return true
                                }
                                else{
                                    return false
                                }
                            }
                            else{       
                                return true
                            }
                        }),
                    },
                    how_often_you_smoke: {
                        required: helpers.withMessage("El campo es requerido.", function (data, index) {
                            if(index.smoke_tobacco=="si"){
                                if(index.how_often_you_smoke.toString()!=""){
                                    return true
                                }
                                else{
                                    return false
                                }
                            }
                            else{       
                                return true
                            }
                        }),
                    },
                    stop_date_smoke: {
                        required: helpers.withMessage("El campo es requerido f.", requiredIf(function () { return false})),
                        date_select_is_future: helpers.withMessage(`La fecha selecionada no es permitida.`, function(data,index) {
                            if (index.contractor_other_than_the_applicant == "si") {
                                let startDate = moment(data, 'DD/MM/YYYY').format('YYYY-MM-DD');
                                let currentDate =  moment().format("YYYY-MM-DD");
                                if( startDate >= currentDate ){
                                    return false;
                                }
                            }
                            return true;
                        })
                    },
                    consume_narcotics: {
                        required: helpers.withMessage("El campo es requerido.", requiredIf(function () { return true})),
                    },
                    list_of_narcotics_types: {
                        required: helpers.withMessage("El campo es requerido.", function (data, index) {
                            if(index.consume_narcotics=="si"){
                                if(index.list_of_narcotics_types.toString()!=""){
                                    return true
                                }
                                else{
                                    return false
                                }
                            }
                            else{       
                                return true
                            }
                        }),
                    },
                    start_date_consume_nacotics: {
                        required: helpers.withMessage("El campo es requerido.", function (data, index) {
                            if(index.consume_narcotics=="si"){
                                if(index.start_date_consume_nacotics.toString()!=""){
                                    return true
                                }
                                else{
                                    return false
                                }
                            }
                            else{       
                                return true
                            }
                        }),
                        date_select_is_future: helpers.withMessage(`La fecha selecionada no es permitida.`, function(data,index) { 
                            if (index.consume_narcotics == "si") {
                                let startDate = moment(data, 'DD/MM/YYYY').format('YYYY-MM-DD');
                                let currentDate =  moment().format("YYYY-MM-DD");
                                if( startDate >= currentDate ){
                                    return  !ValidationsStructure.contracting_data.birthdate_contracting_data.date.value;
                                }
                            }
                            return true;
                        })
                    },
                    drug_use_frequency: {
                        required: helpers.withMessage("El campo es requerido.", function (data, index) {
                            if(index.consume_narcotics=="si"){
                                if(index.drug_use_frequency.toString()!=""){
                                    return true
                                }
                                else{
                                    return false
                                }
                            }
                            else{       
                                return true
                            }
                        }),
                    },
                    end_date_consume: {
                        required: helpers.withMessage("El campo es requerido.", function (data, index) {
                            if(index.consume_narcotics=="si"){
                                if(index.end_date_consume.toString()!=""){
                                    return true
                                }
                                else{
                                    return false
                                }
                            }
                            else{       
                                return true
                            }
                        }),
                        selected_date_is_less_than: helpers.withMessage(`La fecha de útimo consuno tiene que ser mayor a la fecha de consumo.`, function(data,index) { 
                            if (index.consume_narcotics == "si") {
                                let endDateDate = moment(data, 'DD/MM/YYYY').format('YYYY-MM-DD');
                                let currentDate =  moment().format("YYYY-MM-DD");
                                let start_date_consume_nacotics =  moment(index.start_date_consume_nacotics, 'DD/MM/YYYY').format('YYYY-MM-DD');
                                if(start_date_consume_nacotics>=endDateDate && endDateDate<=currentDate){
                                    console.log("Holla")
                                    return false
                                }
                            }
                            return true;
                        }),
                        date_select_is_future: helpers.withMessage(`La fecha selecionada no es permitida.`, function(data,index) {
                            if (index.consume_narcotics == "si") {
                                let startDate = moment(data, 'DD/MM/YYYY').format('YYYY-MM-DD');
                                let currentDate =  moment().format("YYYY-MM-DD");
                                if( startDate >= currentDate ){
                                    return !ValidationsStructure.contracting_data.birthdate_contracting_data.date.value;;
                                }
                            }
                            return true;
                        })
                    },
                    has_recived_treatment: {
                        required: helpers.withMessage("El campo es requerido.", function (data, index) {
                            if(index.consume_narcotics=="si"){
                                if(index.end_date_consume.toString()!=""){
                                    return true
                                }
                                else{
                                    return false
                                }
                            }
                            else{       
                                return true
                            }
                        }),
                    }
                }

                model = {
                    smoke_tobacco: "",
                    tobacco_types: "",
                    how_often_you_smoke: "",
                    stop_date_smoke: "",
                    consume_narcotics: "",
                    list_of_narcotics_types:"",
                    drug_use_frequency: "",
                    end_date_consume: "",
                    has_recived_treatment:"",
                    tobacco_type_list: ""
                } 
            }

            if (category == "alcohol") {
                  validations = {   
                    consume_alcoholic : {
                        required: helpers.withMessage(ValidationsStructure.alcohol.consume_alcoholic.required.message, requiredIf(ValidationsStructure.alcohol.consume_alcoholic.required.value)),
                    },
                    alcohol_type_list: {
                        required: helpers.withMessage(ValidationsStructure.alcohol.alcohol_type_list.required.message, requiredIf(( data, i) => i.consume_alcoholic == "si" ? ValidationsStructure.alcohol.alcohol_type_list.required.value : !ValidationsStructure.alcohol.alcohol_type_list.required.value )),
                    },
                    amount_alcohol: {
                        required: helpers.withMessage(ValidationsStructure.alcohol.amount_alcohol.required.message, requiredIf(( data, i) => i.consume_alcoholic == "si" ? ValidationsStructure.alcohol.amount_alcohol.required.value : !ValidationsStructure.alcohol.amount_alcohol.required.value )),
                    },
                    frequency_of_drinking: {
                        required: helpers.withMessage(ValidationsStructure.alcohol.frequency_of_drinking.required.message, requiredIf(( data, i) => i.consume_alcoholic == "si" ? ValidationsStructure.alcohol.frequency_of_drinking.required.value : !ValidationsStructure.alcohol.frequency_of_drinking.required.value )),
                    },
                    end_date_consume_alcohol: {
                        required: helpers.withMessage(ValidationsStructure.alcohol.end_date_consume_alcohol.required.message, function(data,index){
                            // ValidationsStructure.alcohol.end_date_consume_alcohol.required.value 
                            if(index.consume_alcoholic=="si"){
                                if(index.end_date_consume_alcohol.toString()!=""){
                                    return  !ValidationsStructure.alcohol.end_date_consume_alcohol.required.value  
                                }
                            }
                            return true
                        }),
                        date: helpers.withMessage(ValidationsStructure.alcohol.end_date_consume_alcohol.date.message, function(data,index){
                            if (index.consume_alcoholic == "si") {
                                let startDate = moment(index.end_date_consume_alcohol, 'DD/MM/YYYY').format('YYYY-MM-DD');
                                let currentDate =  moment().format("YYYY-MM-DD");

                                if( startDate >= currentDate ){
                                    return !ValidationsStructure.alcohol.end_date_consume_alcohol.date.value;
                                }
                            }
                            return true
                        
                        })
                    },
                    
                }

                model = {
                    consume_alcoholic: "",
                    alcohol_type_list: "",
                    amount_alcohol: "",
                    frequency_of_drinking: "",
                    end_date_consume_alcohol: ""
                } 
            }

            if (category == "coverages") {
                validations = {
                    added_benefit: {
                        required: helpers.withMessage(ValidationsStructure.coverages.added_benefit.required.message, requiredIf(ValidationsStructure.coverages.added_benefit.required.value)),
                        benefitsConditionals: helpers.withMessage('Solo es posible elegir uno de los beneficios MA, DI o TI.', function(values, index){
         
                            let benefitsConditionals = ['beneficio_adicional_ima', 'beneficio_adicional_imapo', 'beneficio_adicional_dimapo'];
                            let benefits = values.filter(value =>  {
                                if(benefitsConditionals.includes(value.option_id)){
                                    return value.option_id
                                }
                            });
                            return (benefits.length>1)? false: true;
                        })
                    },
                    amount_insured: {
                        required: helpers.withMessage(ValidationsStructure.coverages.amount_insured.required.message, requiredIf(ValidationsStructure.coverages.amount_insured.required.value)),
                        minValue: helpers.withMessage(ValidationsStructure.coverages.amount_insured.min.message, function(value, index){
                            let amountInsured = Number(index.amount_insured);
                            if(amountInsured >= 50000){
                                return true;
                            }
                            return false;
                        }),
                        maxValue: helpers.withMessage(ValidationsStructure.coverages.amount_insured.max.message, function(value, index){
                            let amountInsured = Number(index.amount_insured);
                            if(amountInsured <= 2000000){
                                return true;
                            }
                            return false;
                        }),
                        numberFormat: helpers.withMessage(ValidationsStructure.coverages.amount_insured.numberFormat.message, function (data, index) {
                            return new RegExp(/^[0-9]+([.][0-9]{1,2})?$/).test(index.amount_insured.toString());
                        })
                    }
                }

                model = {
                    added_benefit:"",
                    amount_insured: ""
                }
            }


            vidaSimpleValidations.value = validations;
            vidaSimpleModel.value = model;
        }

        return {
            listOfQuestionPeronalInfo: questionsList,
            changeModelPersonalData,
            next,
            isLoading,
            validations,
            vidaSimpleModel,
            reRender,
            currentCategory,
            ComponentsPersonalDataRef,
            isActive,
            questionTitle,
            imageCategory,
            searchCatalogInQuestion
        }
    },
    components:{
        ComponentsPersonalData,
        Loading
    }

}
</script>
<style>
.question-container > div > div {
    justify-content: space-between;
    width: 100%;
    margin-top: 5px;
    margin-bottom: 15px;
}

.question-container > div > div > button{
    border-radius:5px;
    width:49%;
}

.question-container>div>label, .question-container>div>input, .question-container>div>button {
     font-family: "roboto-regular";
}

.question-container > div > div > button.active {
    background-color: #810042;
    color: white;
    border-color: #810042;
}

.question-container > div > div > button {
    box-sizing: border-box;
    background: #D9D9D9;
    color: white;
    border-radius: 5px;
    font-size: 18px;
    margin-inline: 1px;
}

.question-container>div>label {
    font-size: 18px;
    line-height: 30px;
    color: #485462;
}

.card-shadow {
  box-shadow: 0px 4px 6px -3px rgb(178 176 176);
  -webkit-box-shadow: 0px 4px 6px -3px rgb(178 176 176);
  -moz-box-shadow: 0px 4px 6px -3px rgb(178 176 176);
}

.content_textarea_0 {
    margin-top: 20px;
}

.content_date_3, .content_date_4 {
    margin-top: 9px;
}

.vpd-input-group input {
    flex-grow: 1;
    border: 2px solid #e5e7eb;
    border-right: 2px solid #e5e7eb;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    line-height: 30px;
    padding: 0 10px;
    height: 43px;
}

.content_textarea_6 {
    display:flex;
}

.content_checkbox_1 > div {
    width:100%!important;
}


@media only screen and (min-width: 600px) {
    .content_date_3, .content_date_4 {
        display: block;
    }
}

@media only screen and (max-width: 600px) {
    .content_date_3, .content_date_4 {
        display: flex;
    }
}

#input_rfc_contracting_data_1,
#input_curp_contracting_data_2{
    text-transform: uppercase;
}
</style>
<style scoped>
.button-disabled {
    background-color: #EDD191;
}



@media only screen and (min-width: 600px) {
    .detail-container {
        background-color: #FAFAFA;
    }
    .content_date_3, .content_date_4 {
        display: block;
    }
}
@media only screen and (max-width: 600px) {
    .bg-container {
        background-color: #FCFCFC;
    } 
    .content_date_3, .content_date_4 {
        display: flex;
    }
}
button {
    font-size: 19px!important;
}

</style>